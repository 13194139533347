.footer-links{
    position: relative;
    margin-top: 60px;
    padding: 30px 0;
    &:before{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 1px;
        width: 100vw;
        background-color: $gray-l3;
    }
    ul{
        padding-left: 0;
        li{
            list-style: none;
            margin-right: 20px;
            a{
                color: $heading !important;
                font-weight: 400;
                &:hover{
                    border-style: solid;
                    border-width: 1px;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                }
            }
        }
    }
}