.news-index-page{
    padding-top: 0 !important;
    .intro{
        h1{
            padding-top: 40px !important;
        }
    }
    .image-row-container{
        padding-right: 5px !important;
        padding-left: 5px !important;
        scroll-snap-type: none !important;
        overflow-x: unset !important;
        .news-col{
            img{
                max-height: 220px !important;
            }
            .news-container{
                @include media-breakpoint-down(md){
                    width: 100%;
                }
                .text-container{
                    padding: calc(16px + (30 - 16) * ((100vw - 1200px) / (1920 - 1200))) !important;
                    @media (max-width: 1200px){
                        padding: 20px !important;
                    }
                }
                a{
                    color: $pink !important;
                }
            }
        }
    }
}