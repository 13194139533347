.page-knowledgepage{
    .link-block{
        a{
            color: $heading !important;
            &:hover{
                svg{
                    margin-left: 4px;
                }
            }
            .link-container{
                border: 1px solid $green;
                border-radius: 4px;
                min-height: 70px;
                margin-bottom: 20px;
                padding: 20px 0 20px 30px;
                .title{
                    padding-right: 10px;
                    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
                }
                .arrow{
                    min-height: 32px;
                    min-width: 32px;
                    border-radius: 50%;
                    background-color: $green-l2;
                    margin-left: auto;
                    margin-right: 20px;
                    svg{
                        width: 18px;
                    }
                }
            }
        }
    }
}