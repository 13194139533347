.embed-block{
  margin-bottom: 120px;
  position: relative;
  &::before{
    content: '';
    background-color: $gray-l2;
    height: 100%;
    width: 100%;
    left: -18px;
    top: -28px;
    z-index: -1;
    position: absolute;
    @media(min-width: 768px) and (max-width: 992px){
      content: none;
    }
  }
  .responsive-object{
      position: relative;
      > div{
          padding-bottom: 56.25%;
          position: relative;
          width: 100%;
          iframe{
              top: 0;
              left: 0;
              position: absolute;
              height: 100%;
              width: 100%;
          }
      }
  }
}