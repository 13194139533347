.equipment-block{
    margin-bottom: 30px;
    .description{
        h3{
            font-weight: 700 !important;
        }
        ul{
            padding-left: 30px;
            li{
                list-style: none;
                font-size: 20px;
                line-height: 46px;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    left: -20px;
                    top: 20px;
                    background-color: $pink;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                }
                @include media-breakpoint-down(md){
                    font-size: 18px;
                    line-height: 40px;
                    &::before{
                        top: 18px;
                        width: 6px;
                        height: 6px;
                    }
                }
            }
        }
    }
    .image{
        @include media-breakpoint-down(md){
            margin-bottom: 30px;
        }
        img{
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
    .pricing{
        margin-top: 30px;
        border: 1px solid $blue-d1;
        padding: 25px 30px;
        border-radius: 4px;
        .title{
            font-weight: 900;
            margin-bottom: 16px !important;
        }
        p{
            margin-bottom: 8px;
        }
    }
}