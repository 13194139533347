.form-page, .page-donatepage, .page-orderpage, order-form{
    .form-container{
        padding-top: 50px;
        form{
            label{
                font-size: 18px;
                font-weight: 500;
            }
            ul.fields{
                padding-left: 0;
                li{ 
                    list-style: none;
                    label{
                        margin-bottom: 0;
                        font-weight: 400;
                    }
                    input, textarea{
                        border: 1px solid $gray-l3;
                        border-radius: 4px;
                        margin-bottom: 22px;
                    }
                    &.submit{
                        input{
                            border: none;
                            outline: none;
                            background-color: $pink;
                            width: 230px;
                            color: $white;
                            font-weight: 600;
                            font-size: 18px;
                            margin-top: 20px;
                        }
                    }
                }
            }
            input, textarea{
                padding: 10px;
                height: 51px;                
                width: 100%;
                &.submit-button{
                    width: 136px;
                    background-color: transparent;
                    height: 48px;
                    border-radius: 23.5px;
                    font-size: 18px;
                    font-weight: 500;
                    padding-left: 28px;
                    
                    text-align: unset;
                    -webkit-transition: all 0.4s;
                    -moz-transition: all 0.4s;
                    transition: all 0.4s;
                    &:focus, &.active{
                        box-shadow: none;
                        outline: none;
                    }
                    &.btn-2{
                        overflow: hidden;
                        position: relative;
                    }
                                      
                    &.btn-2:after {
                        content: '';
                        position: absolute;
                        z-index: -1;
                        -webkit-transition: all 0.4s;
                        -moz-transition: all 0.4s;
                        transition: all 0.4s;
                        width: 100%;
                        height: 0;
                        top: 0;
                        left: 0;
                        
                    }
                    &.btn-2:hover:after,
                    &.btn-2:active:after {
                        height: 100%;
                    }
                }
            }
            .submit-container{
                width: 136px;
                margin: 0 auto;
                position: relative;
                &:hover{
                    svg{
                        filter: brightness(10);
                    }
                }
                svg{
                    width: 30px;
                    position: absolute;
                    right: 16px;
                    top: 20px;
                }
            }
            textarea{
                min-height: 250px;
            }
        }
    }
}