.link-row{
    margin-bottom: 20px;
    margin-top: -10px;
    @include media-breakpoint-down(md){
        margin-top: 0;
        padding-top: 40px;
    }
    .link-col{
        position: relative;
        &::before{
            content: '';
            width: 300%;
            height: 1px;
            background-color: $gray-l3;
            left: 14px;
            top: -20px;
            position: absolute;
            @include media-breakpoint-up(md){
                content: none;
            }
        }
        .link-item{
            margin-bottom: 20px;
            a{
                color: $heading !important;
                >div{
                    margin-right: 25px;
                    @include media-breakpoint-down(md){
                        margin-right: 15px;
                    }
                    svg{
                        max-width: 9px;
                        margin-right: 8px;
                    }
                    .title{
                        white-space: nowrap;
                        color: $heading !important;
                        font-size: calc(15px + (16 - 15) * ((100vw - 300px) / (1920 - 300))) !important;
                    }
                }
            }
        }
    }
}