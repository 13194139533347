.page-standardpage, .page-equipmentpage, .page-newspage, .page-donatepage{
    .side-col{
        @include media-breakpoint-down(md){
            padding-right: 17px!important;
        }
        .sidebar{
            background-color: $blue10;
            padding: 38px 36px 28px 60px;
            &.donate-page{
                li{
                    margin-bottom: 16px !important;
                }
            }
            ul{
                padding-left: 0;
                li{
                    margin-bottom: 16px !important;
                    list-style: none;
                    a{
                        color: $heading !important;
                        font-weight: 400;
                    }
                }
            }
        }
        .sidebar{
            background-color: $standard_sidemenu;
        }
    }
    .btn-container{
        border: 1px solid rgba(29, 116, 136, .5);
        border-radius: 8px;
        padding: 30px 35px;
        position: relative;
        display: flex;
        margin-top: 90px;
        flex-wrap: wrap;
        @media (max-width: 1480px){
            justify-content: space-evenly;
            button{
                min-width: 44% !important;
            }
        }
        @media (min-width: 1300px){
            min-width: 700px;
        }
        @media (max-width: 768px){
            button{
                min-width: 100% !important;
            }
        }
        span{
            position: absolute;
            padding: 0 10px;
            background-color: $white;
            font-size: 22px;
            font-weight: 500;
            top: -20px;
        }
        button{
            max-width: 220px;
            box-shadow: none !important;
            height: 61px;
            border: 1px solid $blue-d1;
            background: transparent !important;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 500;
            color: $font !important;
            line-height: 24px;
            padding: 0 !important;
            transition: 0s !important;
            flex: 1;
            min-width: 130px;
            &:hover{
                color: $black !important;
                border: 2px solid $blue-d1;
            }
        }
    }
}