.search-page{
    h4{
        font-weight: 700;
    }
    ul{
        margin-top: 40px;
        padding-left: 0;
        li{
            height: auto;
            border-bottom: 1px solid #D6D6D6;
            padding: 20px 0;
            display: flex;
            justify-content: center;
            list-style: none;
            min-height: 80px;
            flex-direction: column;
            &:hover{
                cursor: pointer;
                a{
                    color: $blue-d1;
                }
            }
            h4{
                margin-bottom: 0;
                line-height: 26px;
                font-size: 24px;
                margin-bottom: 12px;
            }
            a{
                color: $heading;
                p{
                    font-size: 20px;
                    line-height: 30px;
                    a{
                        color: $blue-d1;
                        font-style: italic;
                    }
                }
            }
        }
    }
    .pagination{
        li{
            margin-right: 12px;
            border-bottom: none !important;
            &.active{
                span{
                    color: $blue-d1;
                    border-bottom: 1px solid $blue-d1 !important;
                }
            }
            span{
                font-size: 20px;
                border-bottom: 1px solid #D6D6D6;
            }
        }
    }
}