ul.mobile-menu{ 
    height: 100vh;
    li:not(.donate){
        &:hover{
            a{
                border-bottom: none !important;
            }
        }
        &.open-sub, &.active{
            ul.children{
                left: 0;
                transition: transform .3s linear;
                position: absolute;
                transform: translateX(0);
                top: 0;
                z-index: 10;
                height: auto;
                padding-bottom: 100px;
                a{
                    border-bottom: none !important;
                }
            }
            &.slide-out{
                a{
                    border-bottom: none !important;
                }
                ul.children{
                    transition: transform .3s linear;
                    transform: translateX(110%) !important;
                }
            }
        }
        &.other{
            .nav-link{
                padding-left: 11px !important;
                color: $white;
            }
            svg.love{
                width: 19px;
                margin-right: 12px;
                g>g{
                    stroke: $white !important;
                }
            }
            .arrow{
                margin-right: 0 !important;
                g>g{
                    stroke: $white;
                }
            }
        }
        a, .nav-link{
            min-width: 100%;
            position: relative;
            svg:not(.love){
                width: 25px;
                height: 16px;
                position: absolute;
                right: 20px;
                top: 11px;
                >g>g{
                    stroke: $pink;
                }
            }
        }
    }
    li.donate{
        background-color: $pink !important;
        a{
            color: $white;
            padding-left: 8px !important;
            min-width: 100%;
        }
        svg{
            margin-right: 12px;
        }
        .arrow{
            display: none;
        }
    }
    ul.children{
        transform: translateX(110%);
        padding-top: 20%;
        // transition: transform .3s linear;
        background: $white;
        padding-left: 22px;
        height: 100vh;
        @include media-breakpoint-down(sm){
            padding-top: 22%;
            padding-bottom: 200px !important;
        }
        .back-title{
            background-color: transparent !important;
            box-shadow: none !important;
            margin-bottom: 60px;
            margin-top: -40px;
            padding-left: 0 !important;
            .back-btn{
                outline: none;
                background-color: transparent;
                border: none;

                &:focus, &:active{
                    border: none;
                    outline: none;
                }
                svg{
                    height: 18px;
                    width: 40px;
                }
            }
            svg{
                transform: rotate(-180deg);
                right: unset;
                left: -32px;
                top: 9px;
            }
            a{
                position: relative;
                padding-left: 20px !important;
                &:before{
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 1px;
                    background-color: $heading;
                    height: 31px;
                    top: 2px;
                }
            }
        }
        li.child{
            padding: 24px 28px !important;
            white-space: nowrap;
            box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
            position: relative;
            &.news-page{
                .toggle-btn{
                    display: none !important;
                }
                ul{
                    display: none !important;
                }
            }
            a{
                border-bottom: none !important;
                font-size: 24px;
                line-height: 28px;
            }
            .toggle-btn{
                position: absolute;
                right: 8px;
                top: 7px;
                height: 60px;
                width: 60px;
                cursor: pointer;
                span{
                    display: block;
                    width: 26px;
                    height: 2px;
                    border-radius: 4px;
                    &:first-child{
                        transform: translateX(17px) rotate(90deg);
                        // transition: transform .2s linear;
                    }
                    &:last-child{
                        transform: translateX(-8px);
                    }
                    &.bg-{
                        background-color: $gray;
                    }
                }
            }
            &.open{
                .toggle-btn{
                    span:first-child{
                        transform: translateX(17px) rotate(0);
                        // transition: transform .2s linear;
                    }
                }
            }
            &.open{
                border-width: 1px;
                border-style: solid;
                height: auto !important;
                display: block !important;
                ul.grandchildren{
                    width: calc(100% + 34px);
                    margin-left: -17px;
                    padding-top: 20px;
                    height: 100%;
                    padding-left: 0 !important;
                    li{
                        min-height: 46px !important;
                        height: auto !important;
                        border-radius: 0;
                        padding-left: 0;
                        margin-bottom: 2px;
                        margin-right: 0;
                        &.active{
                            a{
                                font-weight: 700 !important;
                            }
                        }
                        a{
                            padding-left: 16px;
                            white-space: break-spaces;
                            font-weight: 400 !important;
                            font-size: 18px;
                        }
                    }
                }
            }
            ul.grandchildren{
                display: none;
                overflow: hidden;
                height: 500%;
                li{
                    // background-color: $blue10;
                    box-shadow: none !important;
                }
            }
        }
    }
}