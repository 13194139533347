header{
    width: 100vw;
    position: fixed;
    top: 0;
    transform: translateY(0);
    transition: transform .3s linear;
    z-index: 1000;
    @include media-breakpoint-down(lg){
        body.menu-open &{
            background-color: $white;
        }
    }
    &.nav-up{
        transform: translateY(-100%);
        transition: transform .3s linear;
    }
    &:not(.open){
        body:not(.page-searchpage) &{
            .results-container{
                display: none !important;
            }
        }
    }
    nav{
        background-color: $white;
        .navrow{
            height: 150px;
            padding-bottom: 10px;
            @include media-breakpoint-down(md){
                height: 90px;
            }
            .logo{
                z-index: 1010;
                margin-bottom: 10px;
                width: 100%;
                @include media-breakpoint-up(xl){
                    margin-left: -39%;
                }
                @media (min-width: 992px) and (max-width: 1370px){
                    position: absolute;
                    top: 10px;
                    svg{
                        width: 155% !important;
                    }
                }
                @include media-breakpoint-down(md){
                    margin-left: 0;
                }
                svg{
                    width: 175%;
                    max-width: 295px;
                    @include media-breakpoint-down(md){
                        max-width: 213px;
                    }
                }
            }
            .mobile-menu{
                overflow-y: scroll;
                overflow-x: hidden;
                &.menu-container{
                    height: 100vh;
                    padding-bottom: 100px;
                }
                body.menu-open &{
                    position: fixed;
                    top: 0;
                    z-index: 10;
                    left: 0;
                    padding-left: 15px;
                    display: block !important;
                    background-color: $white;
                    padding-top: 20%;
                    @include media-breakpoint-down(sm){
                        top: 90px;
                        padding-top: 22%;
                    }
                    ul{
                        width: 100vw;
                        li{
                            border-radius: 8px;
                            height: 75px;
                            box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
                            margin-bottom: 20px;
                            display: flex;
                            align-items: center;
                            padding-left: 28px;
                            a, .nav-link{
                                font-size: 24px;
                                font-weight: 700 !important;
                            }
                            &.top{
                                background-color: $white;
                            }
                        }
                    }
                }
            }
            .menu-col{
                @media (min-width: 1370px) and (max-width: 1460px){
                    flex: 0 0 62.5% !important;
                    max-width: 62.5% !important;
                }
                @media (min-width: 992px) and (max-width: 1369px){
                    flex: 0 0 87.5% !important;
                    max-width: 87.5% !important;
                }
                @media (min-width: 992px) and (max-width: 1200px){
                    padding-right: 0;
                    margin-top: 40px;
                }
            }
            .menu-container{
                .search-input {
                    height: 0;
                    width: 0;
                    overflow: hidden;
                    display: flex !important;
                    white-space: nowrap;
                    transition: all .3s ease-in-out;
                    &.active {
                        width: 500px;
                        height: 49px;
                        margin-top: 0;
                        z-index: 100;
                        position: absolute;
                        font-family: 'Roboto Condensed', sans-serif;
                        right: 5%;
                        border-radius: 4px;
                        top: 15px;
                        border: 1px solid $blue-d1;
                        flex-wrap: nowrap;
                        transition: width .3s ease-in-out;
                        input {
                            width: 100%;
                            border: none;
                            color: $font;
                            height: 50px;
                            color: $font;
                            font-size: 20px;
                            line-height: 26px;
                            padding-left: 21px;
                            &:focus {
                                outline: none;
                            }
                        }
                        input[type="search"]::-webkit-search-cancel-button{
                            -webkit-appearance: none;
                            position: relative;
                            border-radius: 10px;
                            background-color: $font;
                            transform: rotate(-45deg);
                        }
                        label {
                            width: 100%;
                            position: absolute;
                            top: 28%;
                            left: 21px;
                            span {
                                color: $font;
                                align-self: center;
                            }
                        }
                    }
                    &.on-focus {
                        label {
                            display: none;
                        }
                    }
                }
                .results-container{
                    max-height: 70vh;
                    overflow: scroll;
                    display: block;
                    border: 1px solid #1D7488;
                    border-bottom-right-radius: 4px;
                    border-bottom-left-radius: 4px;
                    position: absolute;
                    height: auto;
                    margin-top: 0;
                    z-index: 100;
                    right: 5%;
                    top: 60px;
                    padding: 12px 20px 40px 20px;
                    background-color: $white;
                    width: 500px;
                    a{
                        .result{
                            min-height: 48px;
                            height: auto;
                            border-bottom: 1px solid #D6D6D6;
                            padding: 0 6px;
                            display: flex;
                            align-items: center;
                            &:hover{
                                background-color: #F7F7F7;
                                h3{
                                    font-weight: 900;
                                }
                            }
                            h3{
                                margin-bottom: 0;
                                font-size: 20px;
                                font-family: 'Roboto Condensed', sans-serif;
                                color: $font;
                            }
                        }
                    }
                    button{
                        height: 60px;
                        width: 100%;
                        border-radius: 4px;
                        background-color: #D91A6E;
                        color: $white;
                        font-size: 20px;
                        font-weight: 500;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;
                        border: none;
                    }
                }
                .pre-nav{
                    margin-bottom: 40px;
                    margin-top: 8px;
                    li{
                        margin-right: 54px;
                        cursor: pointer;
                        &:first-child{
                            position: relative;
                            &::after{
                                content: '';
                                position: absolute;
                                right: -26px;
                                width: 1px;
                                height: 22px;
                                top: 4px;
                                background-color: $font;
                            }
                        }
                        &.search-icon{
                            margin-bottom: 2px;
                            z-index: 100;
                            @media (min-width: 992px) and (max-width: 1640px){
                                margin-right: 0 !important;
                            }
                            @include media-breakpoint-down(lg){
                                margin-right: 26px;
                            } 
                        }
                    }
                }
                body.menu-open &{
                    overflow: hidden;
                }
                ul{
                    &.nav-ul{
                        @media (min-width:992px) and (max-width: 1370px){
                            padding-left: 8.5% !important;
                        }
                    }
                    li{
                        list-style: none;
                        margin-right: calc(20px + (28 - 20) * ((100vw - 1400px) / (1920 - 1400)));
                        &:hover{
                            a{
                                border-bottom: 1px solid $pink;
                            }
                        }
                        @include media-breakpoint-down(md){
                            margin-right: 15px;
                        }
                        @media (min-width: 1920px){
                            margin-right: 28px;
                        }
                        &.active{
                            a{
                                border-bottom: 1px solid $pink;
                                font-weight: 700 !important;
                            }
                        }
                        &.donate{
                            order: 4 !important;
                            @media (min-width:992px) and (max-width: 1370px){
                                margin-right: 0;
                            }
                            @media (max-width: 992px){
                                order: 3 !important;
                            }
                        }
                        &.donate, &.shop{
                            position: relative;
                            width: calc(142px + (170 - 142) * ((100vw - 1200px) / (1920 - 1200)));
                            border-radius: 4px;
                            @media (max-width: 1200px){
                                width: auto;
                            }
                            @include media-breakpoint-down(md){
                                padding-right: 0 !important
                            }
                            &:hover{
                                a{
                                    border-bottom: none !important;
                                }
                            }
                            &.active{
                                a{
                                    border-bottom: none !important;
                                }
                            }
                            svg{
                                height: 100%;
                            }
                            .arrow{
                                svg{
                                    margin-right: 0;
                                    g>g{
                                        stroke: $white;
                                    }
                                }
                            }
                            a{
                                color: $white !important;
                                font-weight: 700 !important;
                            }
                        }
                        &.shop{
                            background-color: $blue-d1 !important;
                            order: 2 !important;
                            @media (min-width: 1640){
                                margin-right: 20px;
                            }
                            @media (min-width: 992px) and (max-width: 1370px){
                                margin-left: auto;
                            }
                            @media (max-width: 992px){
                                order: 4 !important;
                            }
                            // svg.desktop{
                            //     width: calc(128px + (155 - 128) * ((100vw - 1200px) / (1920 - 1200)));
                            // }
                        }
                        a{
                            padding: 0;
                            color: $font !important;
                            font-family: 'Roboto Condensed', sans-serif;
                            font-size: calc(18px + (20 - 18) * ((100vw - 1370px) / (1920 - 1370)));
                            @media (min-width: 1920px){
                                font-size: 20px;
                            }
                            @media (max-width: 1200px){
                                font-size: 18px;
                            }
                            @media (max-width: 992px){
                                font-size: 20px;
                            }
                        }
                    }
                }
            }
        }
    }
    .hamburger-container{
        z-index: 100;
        @include media-breakpoint-down(md){
            position: fixed;
            right: 20px;
            top: 40px;
        }
        .hamburger{
            cursor: pointer;
            span{
                height: 3px;
                width: 30px;
                background-color: $heading;
                margin-bottom: 5px;
                border-radius: 4px;
                display: block;
                transform: none;
                transition: transform .2s linear;
                @include media-breakpoint-down(md){
                    width: 21px;
                    height: 2px;
                }
            }
            &.open{
                span{
                    &:first-child{
                        transform: translate(2px, 2px) rotate(45deg);
                        transition: transform .2s linear;
                    }
                    &:last-child{
                        transition: transform .2s linear;
                        transform: translate(1px, -5px) rotate(-45deg);
                    }
                }
            }
        }
    }
}