.page-equipmentindexpage{
    .equipment-index-page{
        padding-top: 80px;
        @include media-breakpoint-down(md){
            padding-top: 0;
        }
        >.row{
            @include media-breakpoint-down(lg){
                justify-content: center;
            }
            .cards{
                a{
                    h3{
                        text-shadow: 1px 1px 0px $white;
                    }
                }
                .img-container{
                    position: absolute;
                    right: 20px;
                    bottom: 20px;
                    z-index: -1;
                    width: 42%;
                    height: 90%;
                    align-items: flex-end;
                    img{
                        height: auto;
                        object-fit: contain;
                        width: 100%;
                        max-height: 100%;
                    }
                }
            }
        }
    }
}
.page-equipmentpage{
    .header-block{
        display: none;
    }


}

.equipment-order-button{
    display: block;
    background-color: $white;
    height: 65px;
    width: 194px;
    border: 1px solid #D91A6E;
    border-radius: 2px;
    font-size: 18px;
    font-weight: 500;
    color: $heading;
    line-height: 21px;
    transition: background-color linear .2s;
    padding: 20px;
    &:hover{
        background-color: #D91A6E;
        color: $white; 
        transition: background-color linear .2s;
    }
}