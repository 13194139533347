footer{
    position: absolute;
    bottom: 0;
    min-height: 400px;
    width: 100vw;
    overflow: hidden;
    padding-top: 62px;
    background-color: $gray-l2;
    @include media-breakpoint-down(lg){
        padding-bottom: 80px;
    }
    @include media-breakpoint-down(sm){
        .hours-col{
            margin-top: 18px;
            margin-bottom: 18px;
        }
        .contact-col, .hours-col, .location-col, .fb-col{
            padding-left: 20px !important;
        }
        .fb-col{
            &::before{
                left: 20px !important;
            }
            svg{
                width: 32px !important;
                margin-right: 12px !important;
            }
        }
    }
    .logo-row{
        margin-bottom: 20px;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            left: 13.4%;
            height: 1px;
            background-color: $blue-d1;
            bottom: 20px;
            @include media-breakpoint-down(sm){
                left: 17.4%;
            }
        }
        .logo-col{
            @include media-breakpoint-down(lg){
                padding-left: 0;
            }
        }
    }
    .logo{
        margin-left: -62px;
        @include media-breakpoint-down(lg){
            margin-left: 0;
        }
        svg{
            margin-bottom: 36px;
            @include media-breakpoint-down(sm){
                max-width: 213px;
            }
        }
    }
    .fb-col{
        text-align: right;
        @include media-breakpoint-down(lg){
            margin-top: 60px;
            text-align: left;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                left: 0;
                background-color: $blue-d1;
                height: 1px;
                top: -20px;
            }
        }
        svg{
            margin-right: 17px;
            display: inline;
            min-width: 41px;
        }
        p{
            display: inline;
            color: $font !important;
            white-space: nowrap;
        }
    }
    p{
        font-size: 16px;
        line-height: 28px;
    }
}