.table-container{
    margin-bottom: 60px;
    table{
        thead{
            th{
                border-top: none;
                &:first-child{
                    padding-left: 0;
                }
                &.htRight{
                    text-align: right;
                }
            }
        }
        tbody{
            tr{
                td{
                    font-size: calc(15px + (18 - 15) * ((100vw - 300px) / (1920 - 300)));
                    line-height: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
                    &:first-child{
                        padding-left: 0;
                    }
                    &.htRight{
                        text-align: right;
                    }
                }
            }
        }
    }
}