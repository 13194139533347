.page-knowledgeindexpage{
    .header-block{
        overflow: visible !important;
    }
}
.knowledge-index-page{
    padding-top: 80px;
    @include media-breakpoint-down(md){
        padding-top: 20px;
        padding-bottom: 80px;
    }
    .knowledge-index-menu{
        ul.outer-container{
            >li{
                background-color: $white;
                padding: 24px 17px;
                position: relative;
                min-height: unset !important;
                a{
                    font-weight: 400 !important;
                    b{
                        padding-left: 15px;
                        font-size: 24px;
                        color: $heading !important;
                    }
                }
                .toggle-btn{
                    position: absolute;
                    right: 20px;
                    top: 7px;
                    height: 60px;
                    width: 60px;
                    cursor: pointer;
                    span{
                        display: block;
                        width: 26px;
                        height: 2px;
                        border-radius: 4px;
                        &:first-child{
                            transform: translateX(18px) rotate(90deg);
                            transition: transform .2s linear;
                        }
                        &:last-child{
                            transform: translateX(-8px) rotate(0);
                            transition: transform .2s linear;
                        }
                        &.bg-{
                            background-color: $gray;
                        }
                    }
                }
                &.open{
                    &.border-pink{
                        border-color: $pink;
                    }
                    &.border-green{
                        border-color: $green;
                    }
                    &.border-blue{
                        border-color: $blue;
                    }
                    &.border-orange{
                        border-color: $orange;
                    }
                    &.border-yellow{
                        border-color: $yellow;
                    }
                    &.border-purple{
                        border-color: $purple;
                    }
                    .toggle-btn{
                        span{
                            &:first-child{
                                transform: translateX(18px) rotate(45deg);
                                transition: transform .2s linear;
                            }
                            &:last-child{
                                transform: translateX(-8px) rotate(-45deg);
                                transition: transform .2s linear;
                            }
                        }
                    }
                }
                ul.toggle{
                    display: none;
                    padding-left: 0;
                    &.first{
                        margin-top: 18px;
                    }
                    li{
                        margin-bottom: 2px;
                        position: relative;
                        box-shadow: none !important;
                        list-style: none;
                        height: auto !important;
                        .title-container{
                            min-height: 46px !important;
                            padding-left: 15px;
                            width: 100%;
                            padding-top: 8px;
                        }
                        .toggle-btn{
                            width: 30px;
                            height: 30px;
                            span{
                                width: 18px;
                                height: 2px;
                                &:first-child{
                                    transform: translateX(7px) rotate(90deg);
                                    transition: transform .2s linear;
                                }
                                &:last-child{
                                    transform: translateX(-8px);
                                }
                            }
                        }
                        &.open{
                            display: block !important;
                            a.b{
                                font-weight: 700 !important;
                            }
                            .toggle-btn{
                                span:first-child{
                                    transform: translateX(7px) rotate(0);
                                    transition: transform .2s linear;
                                }
                            }
                        }
                        ul.toggle{
                            display: none;
                            padding-left: 15px;
                            li{
                                height: 48px !important;
                                padding-left: 34px;
                                margin-bottom: 2px;
                                position: relative;
                                &::before{
                                    content: '';
                                    position: absolute;
                                    width: 10px;
                                    height: 2px;
                                    background-color: $heading;
                                    left: 14px;
                                    top: 50%;
                                }
                                &:first-child{
                                    margin-top: 2px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}