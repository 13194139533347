.collapse-block{
    margin-bottom: 20px;
    .text-container{
        position: relative;
        border-width: 1px;
        border-style: solid;
        padding: 22px 60px 18px 30px;
        border-radius: 4px;
        .toggle-btn{
            position: absolute;
            right: 20px;
            top: 19px;
            height: 32px;
            width: 32px;
            border-radius: 50%;
            background-color: $gray-l1;
            cursor: pointer;
            span{
                display: block;
                width: 17px;
                height: 2px;
                border-radius: 4px;
                background-color: $gray;
                &:first-child{
                    transform: translateX(8px) rotate(90deg);
                    transition: transform .2s linear;
                }
                &:last-child{
                    transform: translateX(-8px);
                }
            }
        }
        &.open{
            .toggle-btn{
                span:first-child{
                    transform: translateX(8px) rotate(0);
                    transition: transform .2s linear;
                }
            }
        }
        .title{
            font-weight: 700;
            font-size: calc(16px + (18 - 16) * ((100vw - 300px) / (1920 - 300)));
            line-height: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
        }
        .text{
            overflow: hidden;
            display: none;
            ul{
                margin: 18px 0;
                li{
                    line-height: calc(24px + (26 - 24) * ((100vw - 300px) / (1920 - 300)));
                    font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
                    list-style: none;
                    position: relative;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        border-radius: 50%;
                        left: -23px;
                        background-color: $font;
                        top: 10px;
                    }
                }
            }
            p{
                margin-top: 18px;
                font-size: calc(14px + (18 - 14) * ((100vw - 300px) / (1920 - 300)));
                line-height: calc(24px + (26 - 24) * ((100vw - 300px) / (1920 - 300)));
                padding-right: 30px;
                @include media-breakpoint-down(md){
                    padding-right: 0;
                }
            }
        }
    }
}