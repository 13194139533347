$heading: #2F2E41;
$font: #464849;
$standard_sidemenu: #EDF4F6;
$pink: #D9196D;
$pink10: #FBE8F0;
$light-pink: #E87FAA;
$yellow: #F3AB45;
$yellow10: #FEF6EC;
$orange: #E87941;
$orange10: #FDF1EC;
$green-l2: #ACBA8A;
$green: #A7B683;
$green10: #F6F8F2;
$blue: #3D96BF;
$blue-10: #EBF4F8;
$blue-d1: #1D7488;
$blue10: #EBF4F8;
$purple:#99358B;
$purple10:#F5EAF3;

$gray-l5: #D7D8E7;
$gray-l4: #EBF4F8;
$gray-l3: #D7D7E7;
$gray-l2: #F3F8F9;
$gray-l1: #D9D9D9;
$gray: #302F42;