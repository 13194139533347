.page-standardindexpage, .page-standardpage{
    h1{
        font-size: calc(40px + (56 - 40) * ((100vw - 300px) / (1920 - 300))) !important;
        line-height: calc(48px + (66 - 48) * ((100vw - 300px) / (1920 - 300))) !important;
    }
    .standard-index-page{
        position: relative;
        margin-top: 40px;
        padding-bottom: 160px;
        @include media-breakpoint-down(md){
            padding-bottom: 100px;
        }
        .outer-col{
            position: relative;
            &:before{
                content: '';
                width: 91%;
                height: 77%;
                background-color: $gray-l2;
                position: absolute;
                bottom: -40px;
                border-radius: 20px;
                z-index: -1;
                left: 50%;
                transform: translateX(-48%);
            }
            &:after{
                content: '';
                width: 91%;
                height: 80%;
                background-color: $gray-l2;
                position: absolute;
                bottom: 23%;
                border-radius: 20px;
                left: 50%;
                z-index: -1;
                transform: translateX(-48%) skewY(8deg);
            }
            .col-lg-5{
                max-width: 478px !important;
            }
        }
        .cards{
            height: 120px !important;
            min-height: unset;
            padding-left: 57px !important;
            background-color: $white;
            @include media-breakpoint-up(md){
                min-width: 400px;
            }
            .line{
                width: 20px;
                background-color: $pink;
                height: 2px;
                display: inline-block;
                transition: width .3s linear;
                margin-right: 17px;
            }
            h3{
                font-size: 24px !important;
                margin-bottom: 0;
                display: inline-block;
            }
            &:hover{
                cursor: pointer;
                .line{
                    width: 60px;
                    transition: width .3s linear;
                }
            }
        }
        .sidebar{
            transform: none;
            padding: 0;
            background-color: transparent;
            ul.outer-container{
                padding-left: 0;
                li{
                    height: 120px !important;
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px !important;
                    padding-left: 28px !important;
                    a{
                        font-size: 24px;
                        position: relative;
                        font-weight: 700;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 48px;
                            height: 2px;
                            background-color: $pink;
                            left: 0;
                            bottom: -20px;
                        }
                    }
                }
            }
        }
    }
}