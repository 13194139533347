.newest-news-block{
    position: relative;
    padding-top: 160px;
    padding-bottom: 200px;
    position: relative;
    @include media-breakpoint-down(md){
        padding-top: 80px;
    }
    a{
        h2{
            color: $heading !important;
        }
    }
    .background{
        height: 42%;
        width: 78.5%;
        background-color: $gray-l2;
        position: absolute;
        left: 51%;
        top: 18%;
        transform: translateX(-50%);
        @include media-breakpoint-down(md){
            height: 55%;
            width: 110%;
            top: 15%;
        }
        &:before{
            content: '';
            width: 100%;
            height: 77%;
            background-color: $gray-l2;
            position: absolute;
            top: -22%;
            border-radius: 20px;
            z-index: -1;
            left: 48%;
            transform: translateX(-48%) skewY(-3deg);
        }
        &:after{
            content: '';
            width: 100%;
            height: 80%;
            background-color: $gray-l2;
            position: absolute;
            bottom: -35%;
            border-radius: 20px;
            left: 58%;
            z-index: -1;
            transform: translateX(-58%) skewY(8deg);
        }
    }
    .block-title h2{
        font-weight: 300;
        margin-bottom: 30px;
    }
    .newest-news-row{
        flex-wrap: nowrap;
        position: relative;
        .image-row-container{
            padding-left: 40px;
            overflow-x: hidden;
            min-width: 78%;
            padding-right: 40px;
            @include media-breakpoint-down(md){
                scroll-snap-type: x mandatory;
                overflow-x: scroll;
                min-width: 100%;
            }
            .news-col{
                scroll-snap-align: start;
                @media (min-width: 1200px){
                    body:not(.page-newsindexpage) &{
                        flex: 0 0 34.25% !important;
                        max-width: 34.25% !important;
                    }
                }
                &.more{
                    transform: translateX(206%);
                    position: absolute;
                }
                .news-container{
                    height: 455px;
                    background-color: $white;
                    box-shadow: 0 10px 27px 0 rgba(0,0,0, .1);
                    position: relative;
                    @media (max-width: 1400px){
                        height: 405px;
                        img{
                            height: 220px !important;
                        }
                    }
                    @include media-breakpoint-down(md){
                        height: 290px;
                        width: 100%;
                        img{
                            height: 170px !important;
                        }
                    }
                    img{
                        width: 100%;
                        height: 100%;
                        max-height: 270px;
                        object-fit: cover;
                    }
                    .text-container{
                        padding: 30px;
                        position: relative;
                        @include media-breakpoint-down(md){
                            padding: 12px 20px;
                        }
                        span{
                            color: $pink;
                            font-weight: 900;
                            @include media-breakpoint-down(md){
                                font-size: 13px;
                            }
                        }
                        .title{
                            font-weight: 900;
                            color: $heading;
                            @include media-breakpoint-down(md){
                                max-height: 40px;
                                overflow: hidden;
                                font-size: 16px;
                                line-height: 19px;
                            }
                        }
                    }
                    .read-more{
                        position: absolute;
                        left: 30px;
                        bottom: 26px;
                        font-weight: 500;
                        @include media-breakpoint-down(md){
                            bottom: 18px;
                            left: 20px;
                            font-size: 13px;
                            line-height: 15px;
                        }
                    }
                }
            }
        }
    }
    .more-news{
        cursor: pointer;
        position: absolute;
        right: 130px;
        top: calc(50% - 50px);
        @media (max-width: 1440px){
            right: 50px;
        }
        @include media-breakpoint-down(md){
            display: none !important;
        }
    }
}