
.page-knowledgepage{
    .side-col{
        @include media-breakpoint-down(md){
            padding-right: 17px!important;
        }
        .sidebar{
            // background-color: $blue10;
            padding: 38px 36px 28px 60px;
            li{
                list-style: none;
                a{
                    line-height: 26px;
                    color: $heading !important;
                    font-weight: 400;
                }
            }
        }
    }
    .content-col{
        h1{
            padding-top: 80px;
        }
    }
    h1, h2, h3, h4{
        color: $heading !important;
        &.pl-34{
            padding-left: 34px;
        }
    }
    .parent-intro-block{
        margin-top: 100px;
        padding-top: 0;
        padding-bottom: 20px;
        padding-left: 34px;
        padding-right: calc(30px + (150 - 30) * ((100vw - 768px) / (1920 - 768)));
        position: relative;
        margin-bottom: 260px;
        @include media-breakpoint-down(md){
            padding-right: 20px;
            padding-left: 20px;
        }
        p{
            color: $heading !important;
            line-height: calc(28px + (34 - 28) * ((100vw - 768px) / (1920 - 768))) !important;
            font-size: calc(18px + (24 - 18) * ((100vw - 768px) / (1920 - 768))) !important;
            font-weight: 400 !important;
        }
        &::before{
            content: '';
            position: absolute;
            top: -69px;
            left: 0px;
            height: 90px;
            width: 100%;
            border-top-left-radius: 30px;
            border-top-right-radius: 20px;
            background-color: $gray-l4;
            transform: skew(540deg, -2deg);
            z-index: -1;
        }
        &::after{
            content: '';
            position: absolute;
            bottom: -86px;
            left: 0px;
            height: 155px;
            width: 100%;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 20px;
            background-color: $gray-l4;
            transform: skew(540deg, 7deg);
            z-index: -1;
        }
        img{
            float: right;
            width: 200px;
            margin-right: -100px;
            @include media-breakpoint-down(lg){
                margin-right: -30px;
            }
            @include media-breakpoint-down(md){
                margin-right: 0;
            }
        }
    }
    .green10{
        &::before{
            background-color: $green10 !important;
        }
        &::after{
            background-color: $green10 !important;
        }
    }
    .blue10{
        &::before{
            background-color: $blue10 !important;
        }
        &::after{
            background-color: $blue10 !important;
        }
    }
    .orange10{
        &::before{
            background-color: $orange10 !important;
        }
        &::after{
            background-color: $orange10 !important;
        }
    }
    .pink10{
        &::before{
            background-color: $pink10 !important;
        }
        &::after{
            background-color: $pink10 !important;
        }
    }
    .yellow10{
        &::before{
            background-color: $yellow10 !important;
        }
        &::after{
            background-color: $yellow10 !important;
        }
    }
    .purple10{
        &::before{
            background-color: $purple10 !important;
        }
        &::after{
            background-color: $purple10 !important;
        }
    }
}