.page-orderpage{
    .select2-results__options{
        min-width: 300px !important;
    }
    span.select2-dropdown{
        width: unset !important;
        margin-top: -20px;
        ul{
            border-top: 1px solid grey;
            li{
                padding: 8px 22px;
            }
        }
    }
    .form-container{
        .fieldWrapper{
            @include media-breakpoint-up(md){
                &.start_date{
                    width: 48%;
                    display: inline-block;
                }
                &.end_date{
                    width: 48%;
                    float: right;
                }
            }
        }
        ul{
            li{
                span.select2{
                    display: inline !important;
                    span.dropdown-wrapper{
                        height: 30px !important;
                        display: block !important;
                    }
                }
                span.select2-selection{
                    min-height: 51px !important;
                    display: flex !important;
                    align-items: center !important;
                    padding-bottom: 0 !important;
                    li.select2-selection__choice{
                        margin-top: 0 !important;
                        @include media-breakpoint-down(md){
                            margin-top: 5px !important;
                            margin-bottom: 5px !important;
                        }
                    }
                }
                textarea.select2-search__field{
                    min-height: unset !important;
                    border: none !important;
                    margin: 0 10px !important;
                    font-size: 20px !important;
                    height: 25px !important;
                }
            }
        }
    }
}


.order-form {
    .row{
        padding-bottom: 30px;
        padding-top: 30px;
        &.border{
            border-bottom: 1px solid $gray-l3;
            border-top: 1px solid $gray-l3;
            border-left: none !important;
            border-right: none !important;
        }
    }
    .product-container{
        ul{
            li{
                list-style: none;
                p.product-title{
                    font-weight: 600;
                    margin-bottom: 0;
                }
            }
        }
    }
    .cc-icon {
        width: 30px;
    }
    
    .form-control:focus {
        border-color: #4ca746
    }

    .has-error input {
        border-color: red;
    }

    iframe {
        min-height: 500px;
        width: 100%;
        border: 0;
    }

}