html{

}
body {
	-webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: 'Roboto', sans-serif;
    color: $font;
    padding-bottom: 400px;
    position: relative;
    padding-top: 150px;
    @include media-breakpoint-down(md){
        padding-top: 90px;
        padding-bottom: 760px;
    }
    &:not(.page-homepage){
        .cards{
            a{
                color: $font !important;
            }
        }
    }
    &.menu-open{
        position: fixed;
    }
}
section:not(.ch2){
    padding-top: 40px;
    padding-bottom: 80px;
    overflow: hidden;
    @include media-breakpoint-up(lg){
        min-height: 81vh;
    }
    body.page-knowledgepage &{
        padding-bottom: 0;
    }
    @include media-breakpoint-down(md){
        overflow: hidden;
        padding-top: 0;
    }
}
.cards{
    border-radius: 8px;
    overflow: hidden;
    min-height: 227px;
    box-shadow: 0 10px 40px 0 rgba(0,0,0, 0.1);
    transition: box-shadow .3s linear;
    margin-bottom: 52px;
    position: relative;
    @include media-breakpoint-down(sm){
        width: calc(100% - 14px);
        margin: 0 auto 20px auto;
    }
    @include media-breakpoint-up(md){
        &:not(.news-container){
            &:hover{
                transform: scale(1.02);
                box-shadow: 0 10px 40px 0 rgba(0,0,0, 0.2);
                transition: all .3s linear;
                h3.line{
                    &::after{
                        width: 120px;
                        transition: width .3s ease-in;
                    }
                }
            }
        }
    }
    .img-container{
        position: absolute;
        right: 0;
        bottom: 20px;
        z-index: -1;
        img{
            max-width: 228px;
        }
    }
}
img{
    &.center{
        margin: 20px auto;
        display: block;
    }
}
.intro{
    margin-bottom: 50px;
    h1{
        padding-top: 80px;
    }
    p{
        font-size: calc(22px + (24 - 22) * ((100vw - 300px) / (1920 - 300)));
        line-height: calc(28px + (30 - 28) * ((100vw - 300px) / (1920 - 300)));
        font-weight: 300;
    }
}
.border-pink{
    border-color: $pink;
}
.border-green{
    border-color: $green;
}
.border-blue{
    border-color: $blue;
}
.border-orange{
    border-color: $orange;
}
.border-yellow{
    border-color: $yellow;
}
.border-purple{
    border-color: $purple;
}
.bg-pink{
    background-color: $pink;
}
.bg-green{
    background-color: $green;
}
.bg-blue{
    background-color: $blue;
}
.bg-orange{
    background-color: $orange;
}
.bg-yellow{
    background-color: $yellow;
}
.bg-purple{
    background-color: $purple;
}

.bg-pink-10{
    background-color: $pink10;
}
.bg-green-10{
    background-color: $green10;
}
.bg-blue-10{
    background-color: $blue10;
}
.bg-orange-10{
    background-color: $orange10;
}
.bg-yellow-10{
    background-color: $yellow10;
}
.bg-purple-10{
    background-color: $purple10;
}
.green.active{
    &::before{
        background-color: $green;
    }
}
.blue.active{
    &::before{
        background-color: $blue;
    }
}
.orange.active{
    &::before{
        background-color: $orange;
    }
}
.pink.active{
    &::before{
        background-color: $pink;
    }
}
.yellow.active{
    &::before{
        background-color: $yellow;
    }
}
.purple.active{
    &::before{
        background-color: $purple;
    }
}
h3.line{
    position: relative;
    color: $heading !important;
    &::after{
        content: '';
        position: absolute;
        width: 60px;
        transition: width .3s ease-in;
        height: 2px;
        bottom: -20px;
        left: 0;
        background-color: $pink;
    }
    &.color-green{
        &::after{
            background-color: $green !important;
        }
    }
    &.color-blue{
        &::after{
            background-color: $blue !important;
        }
    }
    &.color-pink{
        &::after{
            background-color: $pink !important;
        }
    }
    &.color-orange{
        &::after{
            background-color: $orange !important;
        }
    }
    &.color-yellow{
        &::after{
            background-color: $yellow !important;
        }
    }
    &.color-purple{
        &::after{
            background-color: $purple !important;
        }
    }
}
a{
    color: $blue-d1;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    &:hover{
        text-decoration: none;
    }
}
p{
    font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
}
h1{
    color: $heading;
    font-size: calc(36px + (48 - 36) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(44px + (57 - 44) * ((100vw - 300px) / (1920 - 300)));
    font-weight: 700;
    @media (min-width: 1920px){
        font-size: 48px;
    }
}
h2{
    font-size: calc(30px + (34 - 30) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(34px + (38 - 34) * ((100vw - 300px) / (1920 - 300)));
    @media (min-width: 1920px){
        font-size: 34px;
    }
}
h3{
    font-size: calc(24px + (28 - 24) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(26px + (33 - 26) * ((100vw - 300px) / (1920 - 300)));
    @media (min-width: 1920px){
        font-size: 28px;
    }
}
h4{
    font-size: calc(20px + (24 - 20) * ((100vw - 300px) / (1920 - 300)));
    line-height: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
    @media (min-width: 1920px){
        font-size: 24px;
    }
}