.paragraph-block{
    a{
        color: $blue-d1 !important;
        font-weight: 400;
        border-bottom: 1px solid $blue-d1;
        padding-bottom: 4px;
        i{
            color: $blue-d1 !important;
        }
    }
    p{
        line-height: calc(22px + (30 - 22) * ((100vw - 300px) / (1920 - 300)));
        @media(min-width: 1920px){
            font-size: 20px;
        }
    }
    i{
        color: $pink;
    }
    ol{
        li{
            font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1920 - 300)));
            line-height: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
            @media(min-width: 1920px){
                font-size: 20px;
            }
        }
    }
    ul{
        padding-left: 30px;
        li{
            list-style: none;
            font-size: calc(16px + (20 - 16) * ((100vw - 300px) / (1920 - 300)));
            line-height: calc(22px + (28 - 22) * ((100vw - 300px) / (1920 - 300)));
            position: relative;
            margin-bottom: 20px;
            @media(min-width: 1920px){
                font-size: 20px;
            }
            &::before{
                content: '';
                position: absolute;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                left: -23px;
                top: 10px;
                background-color: $font;
            }
        }
    }
    h2, h3, h4{
        color: $heading;
    }
    h2{
        margin-top: 40px;
        font-weight: 700 !important;
    }
    h3, h4{
        margin-top: 36px;
        > i{
            margin-bottom: 0;
        }
    }
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
        margin: 40px 0;
        &.right{
            float: right;
            margin: 30px -12% 30px 30px;
            width: auto;
            @media (max-width: 1570px){
                width: 100%;
                margin: 30px 0;
            }
        }
    }
}