.image-block{
    img{
        width: 100%;
        height: auto;
        object-fit: cover;
        margin-bottom: 10px;
    }
    .caption{
        font-size: 18px;
        font-style: italic;
        color: $font;
    }
    &.small{
        .img-container{
            max-width: 70%;
            margin: 30px auto;
            @include media-breakpoint-down(lg){
                max-width: 100%;
            }
        }
    }
}