.header{
    margin-bottom: 23px;
    h3.sidemenu-title{
        color: $heading;
    }
    .sidemenu-title-line{
        width: 60px;
        border-bottom-width: 4px;
        border-bottom-style: solid;
        border-radius: 8px;
        margin-top: 23px;
    }
}
.sidebar{
    position: sticky;
    top: 20px;
    padding: 38px 36px;
    @include media-breakpoint-down(md){
        padding: 38px 28px;
    }
    &.bg-{
        background-color: $gray-l2;
    }
    li.first{
        list-style: none;
        margin-bottom: 28px;
        a{
            color: $heading !important;
            font-size: calc(17px + (20 - 17) * ((100vw - 992px) / (1920 - 992)));
            line-height: 26px;
            font-weight: 400;
            word-break: break-word;
        }
        &.active{
            position: relative;
            &::before{
                content: '';
                position: absolute;
                border-radius: 8px;
                background-color: $heading;
                width: 14px;
                height: 1.5px;
                left: -26px;
                top: 12px;
                @include media-breakpoint-down(md){
                    left: -21px;
                }
            }
            a.first{
                font-weight: 700 !important;
            }
            ul.second{
                height: auto;
                padding-left: 26px;
                transition: height .3s ease-in-out;
                margin: 20px 0 14px;
            }
        }
        ul.second{ 
            height: 0; 
            overflow: hidden;
            transition: height .3s ease-in-out;
            li.second{
                list-style: none;
                position: relative;
                margin-bottom: 12px;
                &::before{
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 1.5px;
                    left: -24px;
                    border-radius: 8px;
                    top: 10px;
                }
                &:not(.active){
                    &::before{
                        background-color: $heading;
                    }
                }
                &.active{
                    a.second{
                        font-weight: 700 !important;
                    }
                    ul.toggle{
                        height: auto;
                        padding-left: 26px;
                        transition: height .3s ease-in-out;
                        margin-top: 12px;
                    }
                }
                a.second{
                    font-size: 16px !important;
                    line-height: 20px;
                }
                ul.toggle{ 
                    height: 0; 
                    overflow: hidden;
                    transition: height .3s ease-in-out;
                    .third{
                        list-style: none;
                        position: relative;
                        margin-bottom: 6px;
                        &::before{
                            content: '';
                            position: absolute;
                            width: 10px;
                            height: 1.5px;
                            left: -24px;
                            border-radius: 8px;
                            top: 10px;
                        }
                        &:not(.active){
                            &::before{
                                background-color: $heading;
                            }
                        }
                        a{
                            font-size: 16px !important;
                            line-height: 20px;
                        }
                        &.active{
                            a{
                                font-weight: 700 !important;
                            }
                        }
                    }
                }
            }
        }
    }
}