$grid-columns: 16;
$grid-gutter-width: 34px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1460px
);
@media (max-width: 992px){
    .row > .col,
    .row > [class*="col-"] {
      padding-right: 5px;
      padding-left: 5px;
    }
}
.clamped{
    max-width: 1920px;
}
.break {
    flex-basis: 100%;
    height: 0;
}