.app-link-block{
    margin-bottom: 56px;
    margin-top: 26px;
    .inner-container{
        border: 1px solid $gray-l5;
        border-radius: 12px;
        padding: 22px 32px;
        img{
            height: auto;
            width: 100%;
        }
        .text{
            p{
                font-size: 18px;
                margin-bottom: 0;
            }
        }
        .svg-container{
            svg{
                width: 144px;
            }
            &:first-child{
                svg{
                    margin-right: 50px;
                    width: 157px;
                }
            }
        }
    }
}

@include media-breakpoint-down(sm){
    .app-link-block{
        .inner-container{
            padding: 16px;
            .svg-container{
                svg{
                    width: 100%;
                    max-width: 150px;
                }
                &:first-child{
                    margin-right: 16px;
                    svg{
                        width: 100%;
                    }
                }
            }
        }
    }
}