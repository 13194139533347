.donate-index-page{
    .outer-col{
        position: relative;
        margin-top: 100px;
        margin-bottom: 160px;

        @include media-breakpoint-down(lg){
            justify-content: center;
        }

        &:before{
            content: '';
            width: 76%;
            height: 77%;
            background-color: $gray-l2;
            position: absolute;
            bottom: -40px;
            border-radius: 20px;
            z-index: -1;
            left: 48%;
            transform: translateX(-48%);
        }

        &:after{
            content: '';
            width: 76%;
            height: 80%;
            background-color: $gray-l2;
            position: absolute;
            bottom: 23%;
            border-radius: 20px;
            left: 48%;
            z-index: -1;
            transform: translateX(-48%) skewY(8deg);
        }
        
        @media(min-width: 992px) and (max-width: 1200px){
            &::before{
                width: 96%;
            }   

            &::after{
                width: 96%;
            }   
        }

        @include media-breakpoint-down(md){
            justify-content: center;
        }

        .card-col{
            .card-content{
                background-color: $white;
            }

            &:not(.first-row){
                .card-content{
                    height: 125px;
                    max-height: 125px;
                    min-height: unset;
                    padding-right: 20px;

                    @include media-breakpoint-down(sm){
                        height: 140px;
                        max-height: 140px;
                    }

                    .svg-container{
                        margin-right: 18px;

                        @include media-breakpoint-down(sm){
                            margin-top: 0;
                        }

                        .svg{    
                            width: auto !important;

                            @media(min-width: 992px){
                                width: 100%;
                            }
                        }
                    }

                    h3{
                        font-size: 22px !important;
                        font-weight: 600 !important;

                        @media(min-width: 992px){
                            font-size: calc(16px + (22 - 16) * ((100vw - 1200px) / (1920 - 1200))) !important;
                            line-height: calc(20px + (24 - 20) * ((100vw - 1200px) / (1920 - 1200))) !important;
                        }

                        &::after{
                            content: none;
                        }
                    }

                    button{
                        display: none !important;
                    }
                }
            }
        }

        .first-row{
            margin-bottom: 42px;

            @media(min-width: 992px) and (max-width: 1200px){
                flex: 0 0 33.25% !important;
                max-width: 33.25% !important;

                h3{
                    font-size: 30px !important;
                    line-height: 38px !important;
                }
            }

            .card-content{
                flex-direction: column;
                justify-content: center;
                text-align: center;
                padding-left: calc(15px + (30 - 15) * ((100vw - 1200px) / (1920 - 1200))) !important;
                padding-right: calc(15px + (30 - 15) * ((100vw - 1200px) / (1920 - 1200))) !important;
                padding-bottom: 80px;
                padding-top: 130px;
                min-height: 100%;

                @include media-breakpoint-down(lg){
                    padding-right: 30px !important;
                    padding-left: 30px !important;
                }

                .svg-container{ 
                    top: 70px;
                    left: 50%;
                    transform: translateX(-50%);
                    position: absolute;
                    width: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .svg{
                        width: auto !important;
                    }
                }

                h3{
                    margin-bottom: 50px;
                    font-size: 36px;
                    font-weight: 600 !important;
                    line-height: 42px;

                    &::after{
                        left: 50%;
                        transform: translateX(-50%);
                        bottom: -30px !important;
                    }

                    @include media-breakpoint-down(lg){
                        font-size: 32px;
                    }
                }

                .description{
                    color: $heading;
                    line-height: 25px;
                    font-weight: 400 !important;

                    p{
                        font-size: calc(16px + (18 - 16) * ((100vw - 1200px) / (1920 - 1200))) !important;
                        line-height: calc(22px + (25 - 22) * ((100vw - 1200px) / (1920 - 1200))) !important;

                        @include media-breakpoint-down(lg){
                            font-size: 18px !important;
                            line-height: 24px !important;
                        }
                    }
                }

                button{
                    position: absolute;
                    bottom: 40px;
                    background-color: $white;
                    height: 45px;
                    width: 194px;
                    border: 1px solid #D91A6E;
                    border-radius: 2px;
                    font-size: 18px;
                    font-weight: 500;
                    color: $heading;
                    line-height: 21px;
                    transition: background-color linear .2s;

                    &:hover{
                        background-color: #D91A6E;
                        color: $white; 
                        transition: background-color linear .2s;
                    }
                }
            }
        }
    }
}