.staff-block{
    .member-container{  
        margin-bottom: 30px;
        img{
            max-height: 345px;
            width: 100%;
            object-fit: cover;
        }
        .name{
            color: $heading;
            font-size: 24px;
            font-weight: 700;
            margin-top: 22px;
            line-height: 28px;
        }
        .title{
            color: #D91A6E;
            font-size: 20px;
            font-style: italic;
            line-height: 24px;
            margin-bottom: 30px;
            margin-top: 6px;
        }
        .description{
            font-size: 18px;
            line-height: 26px;
            word-break: break-word;
            color: $heading;
            margin-bottom: 20px;
        }
        .social{
            a{
                margin-right: 12px;
            }
        }
    }
    .intro-col{
        margin-top: 70px;
    }
}