.image-carousel-block{
    position: relative;
    padding-left: 34px;
    background-color: $gray-l2;
    padding-top: 30px;
    padding-right: 17px;
    margin: 60px -18.5% 60px -34px;
    @media(max-width: 1200px){
        margin: 60px -9% 60px -34px;
    }
    @media(max-width: 768px){
        padding-right: 18px;
        min-width: 100vw;
    }
    .image-carousel{
        max-height: 390px;
        position: relative;
        overflow: hidden;
        padding-bottom: 35px;
        flex-wrap: nowrap;
        @media (max-width: 768px){
            overflow: scroll;
        }
        .img-col{
            flex: 0 0 32.9% !important;
            max-width: 32.9% !important;
            overflow: hidden;
            @media (max-width: 1200px){
                flex: 0 0 49.5% !important;
                max-width: 49.5% !important;
                padding-right: 17px !important;
                padding-left: 17px !important;
            }
            @media (max-width: 768px){
                flex: 0 0 86% !important;
                max-width: 86% !important;
            }
            .img-container{
                background-color: $white;
                height: 100%;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .prev, .next{
        position: absolute;
        cursor: pointer;
        @media(max-width: 768px){
            display: none !important;
        }
    }
    .prev{
        top: calc(50% - 16px);
        left: -16px;
        transform: rotate(180deg);
    }
    .next{
        right: -16px;
        top: calc(50% - 16px);
        @media (min-width: 769px) and (max-width: 1200px){
            display: block !important;
        }
    }
}