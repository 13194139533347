.page-donatepage{
    .side-col{
        ul{
            li{
                ul.children{
                    padding-top: 10px;
                    padding-left: 30px;
                    li{
                        list-style: none;
                        position: relative;
                        &::before{
                            content: '';
                            position: absolute;
                            height: 2px;
                            width: 12px;
                            background-color: $heading;
                            left: -20px;
                            top: 12px;
                        }
                        a{   
                            font-size: 16px !important;
                            line-height: 20px;
                        }
                    }
                }
            }
        }
        
    }
    .intro{
        margin-top: 40px;
        .svg-container{
            width: 100%;
            height: auto;
            .svg{
                width: auto !important;
            }
        }
        h1.title{
            margin-bottom: 60px;
            padding-top: 20px;
            position: relative;
            &::after{
                content: '';
                background-color: $pink;
                position: absolute;
                width: 60px;
                height: 2px;
                left: 50%;
                transform: translateX(-50%);
                bottom: -30px !important;
            }
        }
    }
    .btn-container{
        border: 1px solid rgba(29, 116, 136, .5);
        border-radius: 8px;
        padding: 30px 35px;
        position: relative;
        display: flex;
        margin-top: 90px;
        flex-wrap: wrap;
        @media (max-width: 1480px){
            justify-content: space-evenly;
            button{
                min-width: 44% !important;
            }
        }
        @media (min-width: 1300px){
            min-width: 700px;
        }
        @media (max-width: 768px){
            button{
                min-width: 100% !important;
            }
        }
        span{
            position: absolute;
            padding: 0 10px;
            background-color: $white;
            font-size: 22px;
            font-weight: 500;
            top: -20px;
        }
        button{
            max-width: 220px;
            box-shadow: none !important;
            height: 61px;
            border: 1px solid $blue-d1;
            background: transparent !important;
            border-radius: 4px;
            font-size: 18px;
            font-weight: 500;
            color: $font !important;
            line-height: 24px;
            padding: 0 !important;
            transition: 0s !important;
            flex: 1;
            min-width: 130px;
            &:hover{
                color: $black !important;
                border: 2px solid $blue-d1;
            }
        }
    }
}