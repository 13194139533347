.member-association-block{
    .text-container{
        border-color: $blue-d1;
        .chairman, .association{
            color: $heading;
            font-size: 20px;
            padding: 26px 10px;
            border-bottom: 1px solid $font;
            font-weight: 500;
        }
        span{
            display: block;
            font-size: 16px !important;
            color: $heading;
            font-weight: 400;
        }
        .association{
            margin-bottom: 34px;
        }
        a{
            color: $blue-d1 !important;
            font-weight: 400;
            font-size: 20px;
            border-bottom: 1px solid $blue-d1;
            padding-bottom: 4px;
            line-height: 34px;
        }
    }
}    