.header-block{ 
    overflow: hidden;
    @include media-breakpoint-down(md){
        overflow: visible;
    }
    body.page-homepage & {
        overflow: visible;
    }
    @include media-breakpoint-down(xl){
        padding-top: 50px;
    }
    .header-block-row{
    min-height: 520px;
        .text-col{
            body.page-homepage &{

                position: relative;
                &::after{
                    content: '';
                    position: absolute;
                    background-color: $gray-l3;
                    width: 300%;
                    bottom: 60px;
                    left: 17px;
                    height: 1px;
                    z-index: -1;
                }
                @media (max-width: 992px){
                    content: none !important;
                }
            }
            @include media-breakpoint-between(md, lg){
                padding-top: 30px;
            }
            @include media-breakpoint-down(md){
                margin-top: 20px;
            }
            h1.title{
                color: $heading;
                font-size: calc(38px + (70 - 38) * ((100vw - 992px) / (1920 - 992)));
                line-height: calc(46px + (82 - 46) * ((100vw - 992px) / (1920 - 992)));
                font-weight: 700;
                position: relative;
                &::before{
                    content: '';
                    width: 35px;
                    height: 3px;
                    border-radius: 1.5px;
                    background-color: $pink;
                    position: absolute;
                    top: -23px;
                    left: 0;
                    @include media-breakpoint-down(md){
                        content: none;
                    }
                }
                @include media-breakpoint-down(md){
                    font-size: 42px;
                    line-height: 50px;
                }
            }
            p{
                a{
                    border-bottom: 1.5px solid $pink;
                    line-height: 30px;
                }
            }
        }
        .header-image:not(.straight){
            @include media-breakpoint-down(sm){
                margin-top: -40px;
                padding-left: 0;
            }
            .img-wrapper{
                position: relative;
                @include media-breakpoint-down(md){
                    margin-top: 0;
                }
                img{
                    height: auto;
                    width: 106%;
                    @include media-breakpoint-between(md, lg){
                        width: 130%;
                    }
                    @include media-breakpoint-down(md){
                        width: 97%;
                        margin-left: 4%;
                    }
                }
                svg{
                    width: 101%;
                    position: absolute;
                    z-index: 10;
                    top: 20px;
                    right: -40px;
                    height: 88%;
                    @include media-breakpoint-between(md, lg){
                        width: 125%;
                        right: -130px;
                    }
                    @include media-breakpoint-down(md){
                        right: -6px;
                        width: 96%;
                    }

                }
            }
        }
        .header-image.straight{
            .img-wrapper{
                max-height: 450px;
                overflow: hidden;
                border: 1px solid #DDDDEB;
                border-radius: 8px;

                img{
                    height: auto;
                    object-fit: cover;
                    width: 100%;
                }
            }
        }
    }
    .dropdown-row{
        @include media-breakpoint-down(md){
            justify-content: center;
        }
    }
    .dropdown{
        box-shadow: 0 10px 40px 0 rgba(0,0,0,0.1);
        background-color: $white;
        height: 80px;
        border-radius: 4px;
        margin-top: -80px;
        z-index: 10;
        transform: translateX(17px);
        margin-bottom: 50px;
        @include media-breakpoint-between(md, lg){
            margin-top: -200px;
        }
        @include media-breakpoint-down(md){
            height: auto;
            transform: none;
            margin-top: 0;
            margin-bottom: 0;
            align-items: flex-start !important;
            max-width: calc(100% - 26px);
        }
        .content{
            padding-top: 16px;
            padding-bottom: 16px;
            @include media-breakpoint-down(md){
                flex-direction: column !important;
                align-items: flex-start !important;
            }
            .title{
                font-weight: 900;
                font-size: 22px;
                color: $heading;
                @include media-breakpoint-down(md){
                    font-size: 24px;
                    margin-bottom: 10px;
                    padding-left: 15px;
                }
                b{
                    color: $pink;
                }
            }
            .select{
                border: 1px solid $gray-l1;
                min-height: 49px;
                overflow: visible;
                height: 49px;
                position: relative;
                width: 60%;
                border-radius: 2px;
                cursor: pointer;
                padding-left: 16px;
                &::after{
                    content: '';
                    position: absolute;
                    top: 10px;
                    right: 40px;
                    width: 16px;
                    height: 16px;
                    border: 2px solid $pink;
                    border-left: 0;
                    border-top: 0;
                    transform: rotate(45deg);
                }
                @include media-breakpoint-down(md){
                    width: calc(100% - 24px);
                    margin: 0 auto;
                }
                &.open{
                    ul{
                        height: auto;
                        padding: 20px;
                        width: 100%;
                        overflow: scroll;
                        border: 1px solid $gray-l1;
                        li{
                            height: 52px;
                            display: flex;
                            align-items: center;
                            border-bottom: 1px solid $gray-l1;
                            &:hover{
                                a{
                                    color: $blue-d1;
                                    font-weight: 700;
                                }
                            }
                            a{
                                color: $heading;
                                font-size: 20px;
                                font-family: 'Roboto Condensed', sans-serif;
                            }
                        }
                    }
                }
                span{
                    font-size: 18px;
                    color: $heading;
                    @include media-breakpoint-down(md){
                        font-size: 16px;
                    }
                }
                ul{
                    position: absolute;
                    top: 47px;
                    left: 0;
                    background-color: $white;
                    height: 0;
                    width: 0;
                    overflow: hidden;
                    z-index: 1000;
                    li.town{
                        list-style: none;
                    }
                }
            }
        }
    }
}